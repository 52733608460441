import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'talentway-fe-target-script',
  templateUrl: './target-script.component.html',
  styleUrls: ['./target-script.component.scss'],
})
export class TargetScriptComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
